<template>
  <div>
    <v-data-table
      :hide-default-footer="true"
      :headers="headers"
      :items="highlightRules"
      class="elevation-1"
      sort-by="rule_json.priority">
      <template v-slot:item.highlight_columns="{ item }">
        <div v-if="item.highlight_row === true">
          <v-chip>Ganze Zeile</v-chip>
        </div>
        <div v-else class="flex align-items-center flex-column">
          <div
            class="flex items-center pa-1"
            v-for="(col, index) in item.highlight_columns"
            :key="index">
            <v-chip v-if="index < 3" class="mr-1" dark text-caption>
              {{ col.text }}
            </v-chip>
            <span v-if="index === 3" class="grey--text text-caption">
              (+{{ item.highlight_columns.length - 3 }} andere)
            </span>
          </div>
        </div>
      </template>
      <template v-slot:item.color.background="{ item }">
        <v-chip class="flex justify-center" :color="item.color.background" dark>{{ item.color.background }}</v-chip>
      </template>
      <template v-slot:item.color.font="{ item }">
        <v-chip class="flex justify-center" :color="item.color.font" dark>{{ item.color.font }}</v-chip>
      </template>
      <template v-slot:item.is_active="{ item }">
        <v-checkbox v-model="item.is_active" disabled></v-checkbox>
      </template>
      <template v-slot:item.rule_json="{ item }">
        <v-row v-for="(rule, index) in item.rule_json" class="ma-0">
          <v-col class="col pa-1 text-no-wrap flex justify-center">{{rule.columnValue.value}}</v-col>
          <v-col class="col pa-1 text-no-wrap flex justify-center" v-if="rule.compareOperators !== ''">{{rule.compareOperators}}</v-col>
          <v-col class="col pa-1 text-no-wrap flex justify-center" v-else>{{rule.dateCompareOperators}}</v-col>
          <v-col class="col pa-1 text-no-wrap flex justify-center">{{rule.compareValue}}</v-col>
        </v-row>
      </template>

      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Regeln um Zeilen hervorzuheben</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
            content-class="overflow-visible"
            v-model="dialogIsActive"
            max-width="1000px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="openInputDialog(null, 'insert')"
                color="primary"
                dark
                class="mb-2"
                v-bind="attrs"
                v-on="on">
                  Neue Regel
              <v-icon>
                mdi-plus
              </v-icon>
              </v-btn>
            </template>
            <v-stepper class="overflow-visible" non-linear id="menu-anchor">
              <v-stepper-header>
                <v-stepper-step editable @click="stepTwoOpen = false" step="1">
                  Name für Regel
                </v-stepper-step>
                <v-divider></v-divider><v-divider></v-divider>
                <v-stepper-step step="2" @click="stepTwoOpen = true" editable>
                  Regel bestimmen
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3" @click="stepTwoOpen = false" editable>
                  Farbe wählen
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card class="mb-12">
                    <v-text-field
                      label="Name für Regel"
                      v-model="rule.name">
                    </v-text-field>
                    <div class="flex">
                      <v-switch
                        v-model="rule.highlight_row"
                        :label="'Ganze Zeile markieren?'">
                      </v-switch>
                      <v-select
                        v-show="rule.highlight_row === false"
                        class="flex-grow-0 ml-auto w-1/2"
                        multiple
                        :return-object="true"
                        v-model="rule.highlight_columns"
                        :items="columns"
                        item-text="text"
                        :menu-props="{ maxHeight: '400' }"
                        label="Spalte/n auf denen die Regel angewendet werden soll.">
                        <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 3">
                            <span>{{ item.text }}</span>
                          </v-chip>
                          <span
                            v-if="index === 3"
                            class="grey--text text-caption">
                              (+{{ rule.highlight_columns.length - 3 }} others)
                          </span>
                        </template>
                      </v-select>
                    </div>
                    <div class="flex mb-2">
                      <v-switch
                        v-model="rule.is_active"
                        :label="'Aktiv?'">
                      </v-switch>
                      <v-text-field
                        v-model="rule.priority"
                        :label="'Priorität?'"
                        type="number"
                         class="flex-grow-0 w-1/2 ml-auto">
                      </v-text-field>
                    </div>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content step="2" style="overflow: auto">
                  <v-card class="mb-12">
                    <v-container
                      class="grey lighten-5"
                      v-for="(currentRule, index) in rule.rule_json">
                      <v-row class="flex-nowrap" no-gutters>
                        <div>
                          Regel: {{index + 1}}
                        </div>
                        <v-col>
                          <v-card class="pa-2" outlined tile>
                            <v-select
                              :return-object="true"
                              v-model="currentRule.columnValue"
                              :items="columns"
                              item-text="text"
                              :menu-props="{ maxHeight: '400' }"
                              label="Spalte">
                            </v-select>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card class="pa-2" outlined tile>
                            <v-select
                              v-if="getFromThisRuleType(currentRule) === 'date'"
                              label="Vergleichsoperator"
                              :items="dateCompareOperators"
                              v-model="currentRule.dateCompareOperators">
                            </v-select>
                            <v-select
                              v-else
                              label="Vergleichsoperator"
                              :items="compareOperators"
                              v-model="currentRule.compareOperators">
                            </v-select>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card class="pa-2" outlined tile>
                            <v-select
                              v-if="currentRule.columnValue.type === 'date'"
                              label="Datum"
                              :items="dateOperators"
                              v-model="currentRule.compareValue">
                            </v-select>
                            <v-text-field
                              v-else
                              v-model="currentRule.compareValue"
                              label="Vergleichswert">
                            </v-text-field>
                            <div
                                v-if="(currentRule.compareValue === 'eigenes Datum') && stepTwoOpen"
                                @click="switchShowMenu()">
                                {{
                                  new Date(date).toLocaleDateString("de-DE", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                  })
                                }}
                            </div>
                            <v-menu
                                v-model="showMenu"
                                position="absolute"
                                transition="false"
                                v-if="showMenu"
                                attach="#menu-anchor">
                              <v-date-picker
                                  v-model="date"
                                  :show-current="true">
                              </v-date-picker>
                            </v-menu>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-container>
                    <v-btn @click="addRule">+</v-btn>
                  </v-card>
                </v-stepper-content>

                <v-stepper-content class="" step="3">
                  <v-card class="d-flex">
                    <v-col class="color-picker-col">
                      <h3>Hintergrundfarbe</h3>
                      <v-color-picker
                          class="d-inline-block ma-auto"
                          dot-size="25"
                          v-model="rule.color.background"
                          swatches-max-height="200">
                      </v-color-picker>
                    </v-col>
                    <v-col class="color-picker-col">
                      <h3>Schriftfarbe</h3>
                      <v-color-picker
                          class="d-inline-block ma-auto"
                          dot-size="25"
                          v-model="rule.color.font"
                          swatches-max-height="200">
                      </v-color-picker>
                    </v-col>
                  </v-card>
                  <v-card>
                    <v-btn
                        :disabled="!rule.name"
                        color="primary"
                        @click="modifyRule">
                      Save
                    </v-btn>
                    <v-btn @click="dialogIsActive = false" text>
                      Cancel
                    </v-btn>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">
                Wirklich Löschen?
              </v-card-title>
              <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn color="blue darken-1" text @click="dialogDelete = false">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="modifyRule">
                  OK
                </v-btn>
                <v-spacer>
                </v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openInputDialog(item, 'update')">
          mdi-pencil
        </v-icon>
        <v-icon small @click="openConfirmDialog(item, 'delete')">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { deepClone, isValidDate } from "../../helper";
import { defaultHighlightRule, dateOperators } from "../../types";

export default {
  name: "HighlightRules",
  computed: {
    ...mapGetters({
      highlightRules: "highlightRules",
      activeHeaders: "activeHeaders",
      columns: "columns"
    }),

  },
  data() {
    return {
      headers: [
        { text: "Name", value: "name" },
        { text: "Spalte", value: "highlight_columns" },
        // { text: "Regelanzahl", value: "rule_json.length" },
        { text: "Regeln", value:  "rule_json" },
        { text: "Hintergrundfarbe", value: "color.background" },
        { text: "Schriftfarbe", value: "color.font" },
        { text: "Priorität", value: "priority" },
        { text: "Aktiv", value: "is_active" },
        { text: "", value: "actions" },
      ],
      stepTwoOpen: false,
      date: "",
      showMenu: false,
      dialogDelete: false,
      dialogIsActive: false,
      crudAction: "",
      rule: deepClone(defaultHighlightRule),
      compareOperators: [">", ">=", "<", "<=", "=", "like", "!="],
      dateCompareOperators: ["vor", "nach"],
      dateOperators: dateOperators,
    };
  },
  methods: {
    ...mapActions([
      "deleteHighlightRule",
      "updateHighlightRule",
      "createHighlightRule",
    ]),

    getColumns(item) {
      return item.map((x) => x.text).join(", ");
    },

    updateCustomRule() {
      dialogIsActive;
    },

    modifyRule() {
      if (this.rule.rule_json.compareValue === "eigenes Datum") {
        this.rule.rule_json.compareValue = this.date;
      }
      switch (this.crudAction) {
        case "delete":
          this.deleteHighlightRule(this.rule);
          this.dialogDelete = false;
          break;
        case "update":
          this.updateHighlightRule(this.rule);
          break;
        case "insert":
          this.createHighlightRule(this.rule);
          break;
      }
      this.rule = deepClone(defaultHighlightRule);
      this.date = Object.assign({}, "");
      this.dialogIsActive = false;
    },
    openInputDialog(item = null, type) {
      this.crudAction = type;
      this.dialogIsActive = true; 
      if (item) {
        this.rule = deepClone(item);
        this.rule.rule_json = deepClone(item.rule_json);
        if (isValidDate(this.rule.rule_json.compareValue)) {
          this.date = this.rule.rule_json.compareValue;
          this.rule.rule_json.compareValue = "eigenes Datum";
        }
      }
      else {
        this.rule = deepClone(defaultHighlightRule);
        this.date = new Date().toISOString().substr(0, 10);
      }
    },
    openConfirmDialog(item, type) {
      this.dialogDelete = true;
      this.rule = deepClone(item);
      this.crudAction = type;
    },
    addRule() {
      const compareRule = Object.assign({
        columnValue: "",
        compareOperators: "",
        dateCompareOperators: "",
        compareValue: "",
      });
      this.rule.rule_json.push(compareRule);
    },

    switchShowMenu() {
      return this.showMenu = !this.showMenu;
    },
    getFromThisRuleType(rule) {
      try { 
        return rule.columnValue.type;
      } catch {}
    },
  },
};
</script>

<style>
  .color-picker-col {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  tbody td {
    padding: 0 !important;
  }
  .v-input--checkbox .v-input__control .v-input__slot, .v-application .text-start {
    justify-content: center;
    text-align: center !important;
  }
</style>