import Vue from 'vue'
import VueRouter from 'vue-router'
import ProjectOverview from '../views/ProjectOverview.vue'
import Settings from '../views/Settings.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: ProjectOverview,
        beforeEnter(to, from, next) {
            if (store.getters.isLoggedIn) {
                store.dispatch("getTableData");
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        beforeEnter(to, from, next) {
            if (store.getters.isLoggedIn) {
                next();
            } else {
                next('/login');
            }
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        beforeEnter(to, from, next) {
            if (!store.getters.isLoggedIn) {
                next();
            } else {
                next('/');
            }
        }
    }
]

const router = new VueRouter({
    routes
})

export default router