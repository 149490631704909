import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: localStorage.getItem('theme-color') ? localStorage.getItem('theme-color') : '#F7B32B',
        secondary: '#b0bec5',
        anchor: localStorage.getItem('theme-color') ? localStorage.getItem('theme-color') : '#F7B32B',
        accent: '#ff5722',
      },
    },
  },
});
