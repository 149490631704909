<template>
  <div class="w-1/2 mx-auto mt-4">
    <v-data-table
      :headers="headers"
      :items="activeHeaders"
      disable-pagination
      :hide-default-footer="true"
      disable-filtering
      disable-sort
      sort-by="column_position">
      <template v-slot:body="{items}">
        <draggable
          v-model="items"
          @update="saveOrder(items)"
          tag="tbody"
          ghost-class="ghost"
          :disabled="!enabled"
          @start="drag=true" @end="drag=false">
          <tr
            draggable="true"
            class="cursor-grab drop-zone"
            v-for="(item, index) in items"
            :key="item.key">
            <td>
              {{ index + 1 }}
            </td>
            <td class="text-center">
              {{ item.value }}
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "ColumnOrder",
  components: {
    draggable,
  },
  data() {
    return {
      headers: [
        { text: "#", value: "index" },
        { align: "center", text: "Spaltenname", value: "text" },
      ],
      customOrder: [],
      enabled: true,
      dragging: false
    };
  },
  methods: {
    saveOrder(items) {
      items.forEach((item, index) => {
        item.column_position = index;
        if (item.is_custom_column) {
          this.$store.dispatch("updateCustomColumn", item);
        } else {
          this.$store.dispatch("updateRedmineColumn", item);
        }
      });
    },
  },
  computed: {
    activeHeaders: {
      get() {
        return this.$store.getters.activeHeaders;
      },
    },
  },
};
</script>

<style>
  .cursor-grab:hover {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  td {
    text-align: center;
  }
</style>