import { dateCompareValues } from '../types'

export const compareWithOperator = (operator, value, valueToCompare) => {
    switch (operator) {
        case '>':
            return value > valueToCompare;
        case '<':
            return value < valueToCompare;
        case '>=':
            return value >= valueToCompare;
        case '<=':
            return value <= valueToCompare;
        case '=':
            return value === valueToCompare;
        case 'like':
            return String(value).includes(String(valueToCompare));
        case '!=':
            return value !== valueToCompare;
        case 'vor':
            return compareDate(value, valueToCompare);
        case 'nach':
            return compareDate(valueToCompare, value)
    }
}

const compareDate = (value, valueToCompare) => {
    if (isValidDate(valueToCompare) && isValidDate(value)) {
        return new Date(value).getTime() <= new Date(valueToCompare).getTime();
    } 
    else if (value !== "2000-01-01" && valueToCompare !== "2000-01-01") {
        if (!isValidDate(valueToCompare)) {
            return new Date(value).getTime() <= new Date(dateCompareValues[valueToCompare]).getTime();
        } 
        else {
            return new Date(dateCompareValues[value]).getTime() <= new Date(valueToCompare).getTime();
        }
    }
}

export const isValidDate = (date) => {
    return new Date(date) !== "Invalid Date" && !isNaN(new Date(date));
}

export const deepClone = (source) => {
    return JSON.parse(JSON.stringify(source));
}