import Vue from 'vue'
import Vuex from 'vuex'
import { v4 as uuidv4 } from 'uuid';
import * as api from '../api'
import router from '../router'
import {dateOperators, eTableCellStyles} from '../types'
import swal from 'sweetalert';
import {updateTableData} from "../api";
import {deepClone} from "../helper";
import index from "vuex";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        settings: {
            redmineColumns: [],
            customColumns: [],
            refreshTimer: 1000,
            highlightRules: [],
            isLoggedIn: JSON.parse(localStorage.getItem('is_logged_in')) ? JSON.parse(localStorage.getItem('is_logged_in')) : false,
            currentUser: JSON.parse(localStorage.getItem('logged_in_user')) ? JSON.parse(localStorage.getItem('logged_in_user')) : {}
        },
        isLoading: false,
        columns: [],
        data: [],
        users: [],
        errorStatus: false,
        errorMessage: ""
    },
    getters: {
        columns: (state) => {
            return [...state.settings.customColumns, ...state.settings.redmineColumns]
                .sort((x, y) => {
                    return Number(y.column_position) < 0 || Number(x.column_position) < 0 ? 1 : Number(x.column_position) - Number(y.column_position)
                })
        },
        apiKey: (state) => {
            return state.settings.currentUser.api_key;
        },
        user:(state) => {
            return state.settings.currentUser;
        },
        users: (state) => {
            return state.users;
        },
        isLoggedIn: (state) => {
            return state.settings.isLoggedIn;
        },
        data: (state) => {
            return state.data;
        },
        filterOptions: (state) => {
            return Array.from(new Set(state.data.map(x => x[state.settings.filterOptionsKey])))
        },
        headers: (state) => {
            return state.columns;
        },
        activeHeaders: (state) => {
            if (!state.settings.customColumns || !state.settings.redmineColumns)
                return;
            return [...state.settings.customColumns, ...state.settings.redmineColumns]
                .filter(x => Number(x.is_active))
                .sort((x, y) => {
                    return Number(y.column_position) < 0 || Number(x.column_position) < 0 ? 1 : Number(x.column_position) - Number(y.column_position)
                });
        },
        redmineColumns: (state) => {
            return state.settings.redmineColumns
        },
        activeCustomColumns: (state) => {
            return state.settings.customColumns.filter(x => x.isActive);
        },
        customColumns: (state) => {
            return state.settings.customColumns;
        },
        highlightRules: (state) => {
            return state.settings.highlightRules.sort((x, y) => x.rule_json.priority - y.rule_json.priority);
        },
        highlightRuleColumnIds: (state) => {
            return state.settings.highlightRules.map(x => x.comparison.column.id)
        }
    },
    mutations: {
        SET_ERROR_STATUS(state, status) {
            state.errorStatus = status;
        },
        SET_ERROR_MESSAGE(state, message) {
            state.errorMessage = message;
        },
        SET_LOADING_STATUS(state, status) {
            state.isLoading = status;
        },
        SET_LOGIN_STATUS(state, status) {
            state.settings.isLoggedIn = status;
        },
        SET_CURRENT_USER(state, user) {
            state.settings.currentUser = user;
        },
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_DATA(state, data) {
            data.custom.forEach(element => {
                let row = data.redmine.find(row => row.developmentticket_id === element.developmentticket_id);
                if (row) {
                    row[element.key] = element.value;
                }
            });
            state.data = data;
        },
        SET_CUSTOM_COLUMNS(state, customColumns) {
            state.settings.customColumns = customColumns.map(x => {
                x.is_custom_column = true;
                x.text = x.value;
                if ((x.type === 'link') && (x.link === null))
                    x.link = x.key;
                return x;
            });
        },
        SET_REDMINE_COLUMNS(state, redmineColumns) {
            state.settings.redmineColumns = redmineColumns.map(x => {
                x.type = eTableCellStyles[x.type];
                x.text = x.value;
                if ((x.type === 'link') && (x.link === null))
                    x.link = x.key;
                return x;
            });
        },
        SET_COLUMNS(state) {
            const columns = [];
            state.settings.customColumns.forEach(element => {
                columns.push(element);
            });
            state.settings.redmineColumns.forEach(element => {
                columns.push(element);
            });
            state.columns = columns;
        },
        SET_HIGHLIGHT_RULES(state, data) {
            state.settings.highlightRules = [];
            data.forEach(element => {
                const jsonParsedRules = JSON.parse(element.rule_json);
                const jsonParsedColor = JSON.parse(element.color);
                const jsonParsedHighlightColumns = JSON.parse(element.highlight_columns);

                const compareRules = [];
                jsonParsedRules.forEach(rule => {
                    const compareRule = Object.assign({
                        columnValue: rule.columnValue,
                        compareOperators: rule.compareOperators ? rule.compareOperators : "",
                        dateCompareOperators: rule.dateCompareOperators ? rule.dateCompareOperators : "",
                        compareValue: rule.compareValue,
                    });
                    if (compareRule.columnValue.type === "date") {
                        compareRule.compareOperators = "";
                    }
                    else {
                        compareRule.datecompareOperators = "";
                    }
                    compareRules.push(compareRule);
                });
                const highlightRule = Object.assign({
                    api_key: element.api_key,
                    id: element.id,
                    name: element.name,
                    is_active: element.is_active,
                    color: {
                        background: jsonParsedColor.background,
                        font: jsonParsedColor.font,
                    },
                    highlight_row: element.highlight_row,
                    highlight_columns: jsonParsedHighlightColumns,
                    priority: element.priority,
                    rule_json: compareRules,
                });
                state.settings.highlightRules.push(highlightRule);
            });
        },
        ADD_USER(state, user) {
            state.users.push(user);
        },
        ADD_CUSTOM_COLUMN(state, customColumn) {
            state.settings.customColumns.push(customColumn);
        },
        ADD_HIGHLIGHT_RULE(state, rule) {
            state.settings.highlightRules.push(rule);
        },

        DELETE_CUSTOM_COLUMN(state, customColumn) {
            const index = state.settings.customColumns.findIndex(x => x.key === customColumn.key);
            state.settings.customColumns.splice(index, 1);
        },

        DELETE_HIGHLIGHT_RULE(state, rule) {
            const index = state.settings.highlightRules.findIndex(x => x.id === rule.id);
            state.settings.highlightRules.splice(index, 1);
        },

        UPDATE_CUSTOM_COLUMN(state, customColumn) {
            const index = state.settings.customColumns.findIndex(x => x.key === customColumn.key);
            Vue.set(state.settings.customColumns, index, customColumn)
        },
        UPDATE_CUSTOM_DATA(state, customData) {
            let index = state.data.custom.findIndex(x => (x.developmentticket_id === customData.developmentticket_id) && (x.key === customData.key));
            if (index === -1)
                index = state.data.custom.length;
            Vue.set(state.data.custom, index, customData)
        },
        UPDATE_REDMINE_COLUMN(state, redmineColumn) {
            const index = state.settings.redmineColumns.findIndex(x => x.key === redmineColumn.key);
            Vue.set(state.settings.redmineColumns, index, redmineColumn)
        },
        UPDATE_REDMINE_DATA(state, redmineData) {
            const index = state.data.findIndex(x => x.parent_project_id === redmineData.parent_project_id);
            Vue.set(state.data, index, redmineData)
        },
        UPDATE_HIGHLIGHT_RULE(state, rule) {
            const index = state.settings.highlightRules.findIndex(x => x.id === rule.id);
            Vue.set(state.settings.highlightRules, index, rule)
        },
    },
    actions: {
        logUserOut({ commit }) {
            commit('SET_LOGIN_STATUS', false);
            router.push('/login');
        },
        switchUser({ commit }, user) {
            commit('SET_CURRENT_USER', user);
            commit('SET_LOGIN_STATUS', true);
            router.push('/');
        },

        //- Following all API actions -//

        async getTableData({ dispatch, commit, getters, state }) {
            commit('SET_LOADING_STATUS', true);
            let attempt = 0;
            let responsed = false;
            let response;
            while (responsed === false && (attempt < 3)) {
                try {
                    response = await api.getTableData(getters.apiKey);
                    responsed = true;
                }
                catch {
                    attempt++;
                }
            }
            if (attempt !== 3) {
                const { data, highlightRules } = await response.data;
                const { redmine, custom } = await response.data.columns;
                commit('SET_REDMINE_COLUMNS', redmine);
                commit('SET_CUSTOM_COLUMNS', custom);
                commit('SET_COLUMNS');
                commit('SET_DATA', data);
                commit('SET_HIGHLIGHT_RULES', highlightRules);
            } else {
                dispatch('logUserOut');
            }
            commit('SET_LOADING_STATUS', false);
        },
        async getUsers({ commit, state }) {
            const response = await api.getUsers();
            if (!state.errorStatus)
                commit('SET_USERS', response.data.users)
        },

        async createUser({ commit, dispatch, state }, user) {
            const createUser = Object.assign({
                api_key: user.api_key,
                user_name: user.user_name
            });
            await api.createUser(createUser);
            if (!state.errorStatus) {
                commit('ADD_USER', createUser);
                dispatch('switchUser', createUser);
            }
        },
        async createCustomColumn({ commit, getters, state }, column) {
            if (!column.link || (column.link === 'Keine Angabe'))
                column.link = null;
            if (!column.is_active)
                column.is_active = false;
            const updateColumn = Object.assign({
                api_key: getters.apiKey,
                key: column.text.toLowerCase().replace(/ /g,"_"),
                value: column.text,
                type: column.type,
                link: column.link,
                is_active: column.is_active,
                column_position: -1
            });
            await api.createCustomColumn(updateColumn);
            updateColumn.text = column.text;
            if (!state.errorStatus)
                commit('ADD_CUSTOM_COLUMN', updateColumn)
        },
        async createHighlightRule({ commit, getters, state }, rule) {
            rule.rule_json.highlight_row = rule.highlight_row;
            const updateRule = Object.assign({
                api_key: getters.apiKey,
                id: uuidv4(),
                name: rule.name,
                is_active: rule.is_active,
                color: JSON.stringify(rule.color),
                highlight_row: rule.highlight_row,
                highlight_columns: JSON.stringify(rule.highlight_columns),
                priority: rule.priority,
                rule_json: JSON.stringify(rule.rule_json)
            });
            rule.id = updateRule.id;
            await api.createHighlightRule(updateRule);
            if (!state.errorStatus)
                commit('ADD_HIGHLIGHT_RULE', rule);
        },

        async deleteUser({ commit, dispatch, state }) {
            await api.deleteUser(state.settings.currentUser.api_key);
            if (!state.errorStatus) {
                dispatch('logUserOut');
            }
        },
        async deleteCustomColumn({ commit, getters, state }, column) {
            const updateColumn = Object.assign({}, column);
            Object.keys(updateColumn).forEach(element => {
                if (!(element === "api_key" || element === "key"))
                    delete updateColumn[element];
            })
            await api.deleteCustomColumn(updateColumn);
            if (!state.errorStatus)
                commit('DELETE_CUSTOM_COLUMN', updateColumn);
        },
        async deleteHighlightRule({ commit, getters, state }, rule) {
            const highlightRule = Object.assign({
                id: rule.id,
            });
            await api.deleteHighlightRule(highlightRule);
            if (!state.errorStatus)
                commit('DELETE_HIGHLIGHT_RULE', rule);
        },
        async updateTableData({ commit, dispatch, state }) {
            commit('SET_LOADING_STATUS', true);
            let attempt = 0;
            let responsed = false;
            let response;
            while (responsed === false && (attempt < 3)) {
                try {
                    response = await api.updateTableData(state.settings.currentUser.api_key);
                    responsed = true;
                }
                catch {
                    attempt++;
                }
            }
            if (attempt !== 3) {
                const { data, highlightRules } = await response.data;
                const { redmine, custom } = await response.data.columns;
                if (redmine) {
                    commit('SET_REDMINE_COLUMNS', redmine);
                }
                if (custom) {
                    commit('SET_CUSTOM_COLUMNS', custom);
                }
                commit('SET_COLUMNS');
                if (data) {
                    commit('SET_DATA', data);
                }
                if (highlightRules) {
                    commit('SET_HIGHLIGHT_RULES', highlightRules);
                }
            }
            commit('SET_LOADING_STATUS', false);
        },
        async updateCustomColumn({ commit, getters, state }, column) {
            if (column.link === 'Keine Angabe')
                column.link = null;
            const updateColumn = Object.assign({
                api_key: column.api_key,
                key: column.key,
                value: column.text,
                link: column.link,
                type: column.type,
                is_active: column.is_active,
                column_position: column.column_position
            });
            await api.updateCustomColumn(updateColumn);
            if (!state.errorStatus)
                commit('UPDATE_CUSTOM_COLUMN', column);
        },
        async updateCustomData({ commit, getters, state }, { header, item }) {
            const dataToSend = new Object({
                api_key: header.api_key,
                developmentticket_id: item.developmentticket_id,
                key: header.key,
                value: item[header.key],
            });
            if ((dataToSend.value !== null) && (dataToSend.value !== undefined)) {
                await api.updateCustomData(dataToSend);
                if (!state.errorStatus)
                    commit('UPDATE_CUSTOM_DATA', dataToSend);
            }
        },
        async updateRedmineColumn({ commit, getters, state }, column) {
            if (column.link === 'Keine Angabe')
                column.link = null;
            const updateColumn = Object.assign({
                api_key: column.api_key,
                key: column.key,
                value: column.text,
                type: column.type,
                link: column.link,
                is_active: column.is_active,
                column_position: column.column_position
            });
            await api.updateRedmineColumn(updateColumn);
            if (!state.errorStatus)
                commit('UPDATE_REDMINE_COLUMN', column);
        },
        async updateRedmineData({ commit, getters, state }, { header, item }) {
            const dataToSend = new Object({
                api_key: header.api_key,
                developmentticket_id: item.developmentticket_id,
                key: header.key,
                value: item[header.key],
            });
            await api.updateRedmineData(dataToSend)
            if (!state.errorStatus)
                commit('UPDATE_REDMINE_DATA', item);
        },
        async updateHighlightRule({ commit, getters, state }, rule) {
            if (!rule.highlight_columns) {
                rule.highlight_columns = null;
            }
            const updateHighlightRule = Object.assign({
                api_key: rule.api_key,
                id: rule.id,
                name: rule.name,
                is_active: rule.is_active,
                color: JSON.stringify(rule.color),
                highlight_row: rule.highlight_row,
                highlight_columns: JSON.stringify(rule.highlight_columns),
                priority: rule.priority,
                rule_json: JSON.stringify(rule.rule_json)
            });
            await api.updateHighlightRule(updateHighlightRule);
            if (!state.errorStatus)
                commit('UPDATE_HIGHLIGHT_RULE', rule);
        },
    },
})

