<template>
  <v-data-table
    :hide-default-footer="true"
    :headers="headers"
    :items="customColumns"
    class="elevation-1">
    <template v-slot:item.is_active="{ item }">
      <v-checkbox
          v-model="item.is_active"
        @click="toggleState(item)">
      </v-checkbox>
    </template>
    <template v-slot:item.text="{ item }">
      <td v-if="!item.link">
        {{item.text}}
      </td>
      <td v-else>
        {{item.text}} | {{item.link}}
      </td>
    </template>
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>
          Eigene Spalten
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical>
        </v-divider>
        <v-spacer>
        </v-spacer>
        <v-dialog v-model="dialogIsActive" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              @click="openInputDialog('insert')"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on">
                Neue Spalte
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">
                {{ dialogTitle }}
              </span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                      v-model="input.text"
                      label="Name">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select
                      :items="cellStyles"
                      label="Type"
                      v-model="input.type">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                      <v-select
                      :items="getColumns()"
                      label="Spalte mit URL"
                      v-model="input.link">
                    </v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-checkbox
                      v-model="input.is_active"
                      :label="'Aktive?'">
                    </v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer>
              </v-spacer>
              <v-btn
                color="blue darken-1"
                v-ripple
                text
                @click="dialogIsActive = false">
                  Abbrechen
              </v-btn>
              <v-btn
                :disabled="!input.text || !input.type"
                color="blue darken-1"
                v-ripple
                text
                @click="modifyColumn">
                  Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">
              Wirklich Löschen?
            </v-card-title>
            <v-card-actions>
              <v-spacer>
              </v-spacer>
              <v-btn color="blue darken-1" text @click="dialogDelete = false">
                Cancel
              </v-btn>
              <v-btn color="blue darken-1" text @click="modifyColumn">
                OK
              </v-btn>
              <v-spacer>
              </v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="openInputDialog('update', item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="openConfirmDialog(item, 'delete')">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";

import { cellStylesCustomColumns } from "../../types/index.js";

export default {
  name: "UserColumns",
  computed: {
    ...mapGetters({
      allColumns: "columns",
      customColumns: "customColumns",
      activeColumns: "activeHeaders",
    }),
  },
  data() {
    return {
      cellStyles: cellStylesCustomColumns,
      crudAction: "",
      dialogTitle: "",
      dialogIsActive: false,
      dialogDelete: false,
      input: {
        value: "",
        type: "",
        is_active: "",
     },
      headers: [
        {
          text: "Name",
          sortable: true,
          value: "text",
        },
        {
          text: "Typ",
          sortable: true,
          value: "type",
        },
        {
          text: "Aktiv",
          sortable: true,
          value: "is_active",
        },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    getColumns() {
      const columns = ['Keine Angabe'];
      this.allColumns.forEach(element => {
        if (element.type === "link")
          columns.push(element.key);
      });
      return columns;
    },
    toggleState(item) {
      this.$store.dispatch("updateCustomColumn", item);
    },
    modifyColumn() {
      switch (this.crudAction) {
        case "delete":
          this.$store.dispatch("deleteCustomColumn", this.input);
          this.dialogDelete = false;
          break;
        case "update":
            this.$store.dispatch("updateCustomColumn", this.input);
          break;
        case "insert":
          this.$store.dispatch("createCustomColumn", this.input);
          break;
      }
      this.input = Object.assign({}, { type: "" });
      this.dialogIsActive = false;
    },
    openInputDialog(type, item = null) {
      this.crudAction = type;
      this.dialogIsActive = true;
      this.input = Object.assign({}, item);
    },
    openConfirmDialog(item, type) {
      this.dialogDelete = true;
      this.input = Object.assign({}, item);
      this.crudAction = type;
    },
  },
};
</script>

<style>
</style>
