<template>
  <div class="grid grid-cols-3 p-4 bg-gray-100 shadow-xl mb-4">
    <router-link class="flex justify-center col-start-2 col-end-2" to="/">
      <div class="text-5xl text-lime-400 m-auto color-gray-500">
        Projektübersicht
      </div>
    </router-link>
    <div class="user-name justify-end flex p-2">{{ user.user_name }}</div>
    <router-link
      v-if="isLoggedIn"
      class="settings flex justify-end col-start-4 col-end-4 p-2"
      to="/settings">
      <span class="self-end text-xl">
        <v-icon color="black" large>
          mdi-cog
        </v-icon>
      </span>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  computed: {
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
        user: "user"

    }),
  },
};
</script>

<style>
    .user-name {
        font-size: 1.25rem;
        font-weight: 700;
    }
</style>
