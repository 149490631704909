<template>
    <div class="flex justify-center">
    <Table></Table>
  </div>

</template>

<script>
import Table from "../components/Table";

export default {
  name: "Home",
  components: {
    Table,
  },
};
</script>
