<template>
  <v-card elevation="1">
    <div class="mt-8 flex justify-center">
      <v-btn class="w-1/4" color="primary" @click="logOff()" elevation="2">
        Benutzer wechseln 👥
      </v-btn>
    </div>
    <div class="mt-12 flex justify-center">
      <v-menu right offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="w-1/4 " elevation="2" color="primary" v-bind="attrs" v-on="on">
            Theme Farbe ändern 🎨
          </v-btn>
        </template>
        <v-color-picker
          v-model="themeColor"
          class="ma-2"
          show-swatches>
        </v-color-picker>
      </v-menu>
    </div>
    <div class="mt-12 flex justify-center">
      <v-btn class="w-1/4" elevation="2" depressed color="error" @click.stop="dialog = true">
        Account löschen 🗑️
      </v-btn>
      <v-dialog v-model="dialog" max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            Account löschen? 🗑️
          </v-card-title>
          <v-card-actions>
            <v-spacer>
            </v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Nein
            </v-btn>
            <v-btn color="green darken-1" text @click="deleteUser">
              Ja
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
export default {
  name: "GeneralSettings",
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    logOff() {
      this.$store.dispatch("logUserOut");
    },
    deleteUser(){
      this.dialog = false;
      this.$store.dispatch('deleteUser');
    }
  },
  computed: {
    themeColor: {
      get() {
        return localStorage.getItem("theme-color")
          ? localStorage.getItem("theme-color")
          : this.$vuetify.theme.themes.light.primary;
      },
      set(color) {
        localStorage.setItem("theme-color", color);
        this.$vuetify.theme.themes.light.primary = color;
        this.$vuetify.theme.themes.light.anchor = color;
      },
    },
  },
};
</script>

<style>
</style>