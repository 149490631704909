export const cellStyles = [
    "date",
    "text",
    "number",
    "link",
    "boolean",
    "percent"
]

export const cellStylesCustomColumns = [
    "date",
    "text",
    "number",
    "boolean",
    "link",
    "percent"
]

export const dateOperators = [
    "eigenes Datum",
    "heute",
    "heute in einer Woche",
    "heute in zwei Wochen",
    "heute in einem Monat",
];

export const eTableCellStyles = {
    "int": "number",
    "varchar": "text",
    "tinyint": "boolean",
    "date": "date",
    ...cellStyles.reduce(function(o, val) { o[val] = val; return o; }, {})
}

export const dateCompareValues = {
    "heute": new Date(),
    "heute in einer Woche": new Date().setDate(new Date().getDate() + 6),
    "heute in zwei Wochen": new Date().setDate(new Date().getDate() + 14),
    "heute in einem Monat": new Date().setMonth(new Date().getMonth() + 1),
}

export const defaultHighlightRule = {
    id: "",
    api_key: "",
    name: "",
    is_active: false,
    highlight_row: false,
    highlight_columns: [],
    priority: 0,
    color: {
        background: "",
        font: "",
    },
    rule_json: [{
            columnValue: "",
            compareOperator: "",
            compareValue: ""
    }],
}