<template>
  <div class="max-w-screen-2xl mx-auto bg-red">
    <v-tabs class="">
      <v-tab href="#general">Allgemeine Einstellungen</v-tab>
      <v-tab-item value="general">
        <GeneralSettings></GeneralSettings>
      </v-tab-item>

      <v-tab href="#column_order">Spaltenreihenfolge</v-tab>
      <v-tab-item value="column_order">
        <ColumnOrder></ColumnOrder>
      </v-tab-item>

      <v-tab href="#redmine-columns">Redminespalten</v-tab>
      <v-tab-item value="redmine-columns">
        <RedmineColumns></RedmineColumns>
      </v-tab-item>

      <v-tab href="#user-columns">Eigene Spalten</v-tab>
      <v-tab-item value="user-columns">
        <UserColumns></UserColumns>
      </v-tab-item>

      <v-tab href="#highlight-rules">Regeln zur Hervorhebung</v-tab>
      <v-tab-item value="highlight-rules">
        <HighlightRules></HighlightRules>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import RedmineColumns from "../components/Settings/RedmineColumns";
import UserColumns from "../components/Settings/UserColumns";
import HighlightRules from "../components/Settings/HighlightRules";
import GeneralSettings from "../components/Settings/GeneralSettings";
import ColumnOrder from "../components/Settings/ColumnOrder";


export default {
  name: "Settings",
  components: {
    RedmineColumns,
    UserColumns,
    HighlightRules,
    GeneralSettings,
    ColumnOrder,
  },
};
</script>

<style>
</style>