<template>
  <div class="flex flex-column align-center px-24">
    <div>
      <v-btn @click="dialogIsOpen = true" elevation="2" x-large text rounded>
        <v-icon>
          mdi-plus
        </v-icon>
        Nutzer hinzufügen
      </v-btn>
    </div>
    <div class="mt-12 grid grid-cols-3 gap-5">
      <v-avatar
        v-for="user in users"
        :key="user.api_key"
        color="primary"
        size="100"
        @click="switchUser(user)">
        {{ user.user_name }}
      </v-avatar>
    </div>
    <v-dialog v-model="dialogIsOpen" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Nutzer hinzufügen
        </v-card-title>
        <v-text-field
          required
          class="px-12 py-4"
          v-model="newUser.api_key"
          label="Redmine-Api-Key"
          placeholder=""
        ></v-text-field>
        <v-text-field
          required
          class="px-12 py-4"
          v-model="newUser.user_name"
          label="Name"
        ></v-text-field>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled=" ((newUser.api_key.length != 40) || !newUser.user_name)"
            color="primary"
            @click="createUser"
            text
            >Hinzufügen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      valid: false,
      dialogIsOpen: false,
      newUser: {
        api_key: "",
        user_name: "",
      },
    };
  },
  beforeMount() {
    this.$store.dispatch("getUsers");
  },
  computed: {
    ...mapGetters({
      users: "users",
    }),
  },
  methods: {
    createUser() {
      this.dialogIsOpen = false;
      this.$store.dispatch("createUser", this.newUser);
    },
    switchUser(user) {
      this.$store.dispatch("switchUser", user);
    },
  },
};
</script>

<style>
</style>