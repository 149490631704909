import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './index.css'

Vue.config.productionTip = false

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')


//SAVE LOGIN CREDENTIELS
store.subscribe((mutation, state) => {
    if (mutation.type === 'SET_CURRENT_USER') {
        localStorage.setItem('logged_in_user', JSON.stringify(state.settings.currentUser));
    }
    if (mutation.type === 'SET_LOGIN_STATUS') {
        localStorage.setItem('is_logged_in', JSON.stringify(mutation.payload));
    }
});