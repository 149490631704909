<template>
  <div>
    <v-data-table
      :hide-default-footer="true"
      :headers="headers"
      :items="redmineColumns"
      :sort-desc="[true, false]"
      class="elevation-1"
      disable-pagination>
      <template v-slot:item.is_active="{ item }">
        <v-checkbox
          v-model="item.is_active"
          @change="toggleState(item)">
        </v-checkbox>
      </template>
      <template v-slot:item.text="{ item }">
        <td v-if="!item.link">
          {{item.text}}
        </td>
        <td v-else>
          {{item.text}} | {{item.link}}
        </td>
      </template>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>
            Redmine Spalten
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical>
          </v-divider>
          <v-spacer>
          </v-spacer>
          <v-dialog v-model="dialogIsActive" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="text-h5">
                  {{ dialogTitle }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field
                        v-model="input.text"
                        label="Name">
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="cellStyles"
                        label="Type"
                        v-model="input.type">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select
                        :items="getColumns()"
                        item-text="text"
                        label="Link"
                        v-model="input.link">
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-checkbox
                        v-model="input.is_active"
                        :label="'Aktive?'">
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer>
                </v-spacer>
                <v-btn
                  color="blue darken-1"
                  v-ripple
                  text
                  @click="dialogIsActive = false">
                    Abbrechen
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  v-ripple
                  text
                  :disabled="!input.type || !input.text"
                  @click="updateColumn()">
                    Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="openInputDialog(item)">
          mdi-pencil
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cellStyles } from "../../types/index";

export default {
  name: "RedmineColumns",

  data() {
    return {
      cellStyles: cellStyles,
      dialogTitle: "",
      dialogIsActive: false,
      dialogDelete: false,
      input: {
        text: "",
        is_active: "",
        type: "",
      },
      headers: [
        {
          text: "Feld",
          sortable: true,
          value: "key",
        },
        {
          text: "Name",
          sortable: true,
          value: "text",
        },
        {
          text: "Typ",
          sortable: true,
          value: "type",
        },
        {
          text: "Aktiv",
          sortable: true,
          value: "is_active",
        },
        {
          text: "",
          value: "actions",
          sortable: false
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      allColumns: "columns",
      redmineColumns: "redmineColumns",
    }),
  },
  methods: {
    getColumns() {
      const columns = ['Keine Angabe'];
      this.allColumns.forEach(element => {
        if (element.type === "link")
          columns.push(element.key);
      });
      return columns;
    },
    toggleState(item) {
      this.$store.dispatch("updateRedmineColumn", { ...item });
    },
    updateColumn() {
      this.$store.dispatch("updateRedmineColumn", { ...this.input });
      this.input = Object.assign({}, {});
      this.dialogIsActive = false;
    },
    openInputDialog(item = null) {
      this.dialogIsActive = true;
      if (item)
        this.input = { ...item };
    },
  },
};
</script>

<style>
</style>


{
  "columns":[
    {
      "id":"493dc103-441a-4165-bac2-75b6f56dd442",
      "is_custom_column":"1",
      "value":"System",
      "text":"System",
      "type":"text",
      "api_key":"8efa5e2483b623200c63ab5e0d4b7efc66d04aca",
      "is_active":"1",
      "column_position":"-1"
    },
  ],
  "columnValue":
    {
      "column_name":"developmentticket_due_date",
      "value":"developmentticket_due_date",
      "text":"Deadline Entw.",
      "is_active":"1",
      "type":"date",
      "api_key":"8efa5e2483b623200c63ab5e0d4b7efc66d04aca",
      "column_position":"8"
    },
  "compareOperator":"vor",
  "compareValue":"heute in zwei Wochen",
  "priority":0
}