<template>
  <v-app id="app">
    <Header></Header>
    <v-overlay :value="isLoading">     
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar :multi-line="true" timeout="-1" v-model="errorStatus">
      <v-icon>
        mdi-alert-circle
      </v-icon>
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="errorStatus = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <router-view>
    </router-view>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "App",
  components: {
    Header,
  },
  computed: {
    ...mapState({
      isLoading: "isLoading",
      errorMessage: "errorMessage",
    }),
    ...mapGetters({
      isLoggedIn: "isLoggedIn",
    }),
    errorStatus: {
      get() {
        return this.$store.state.errorStatus;
      },
      set(newValue) {
        this.$store.commit("SET_ERROR_STATUS", newValue);
      },
    },
  },
  beforeCreate() {
    if (this.isLoggedIn) {
      this.$store.dispatch("getTableData");
    }
  },
};
</script>
<style>
</style>