<template>
  <div class="flex-1 display-6" style="max-width: 90%">
    <div class="flex items-center mb-3">
      <!--      <v-text-field-->
      <!--        :height="35"-->
      <!--        v-model="search"-->
      <!--        append-icon="mdi-magnify"-->
      <!--        label="Suchen"-->
      <!--        single-line-->
      <!--        hide-details-->
      <!--        class="mb-4 w-1/4 flex-none">-->
      <!--      </v-text-field>-->
      <div class="ml-auto">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-4" v-bind="attrs" v-on="on" color="primary" v-on:click="reload">
              Projekte:
              <div v-if="data.redmine">
                {{ data.redmine.length }}
              </div>
              <div v-else>
                0
              </div>
              <v-icon dark right>
                mdi-calendar-check
              </v-icon>
            </v-btn>
          </template>
          <span>
            Stand Daten:
            {{
              new Date().toLocaleDateString("de-DE", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
              })
            }}
          </span>
        </v-tooltip>
        <v-btn @click="printTable" class="" color="primary">
          Drucken
          <v-icon dark right>
            mdi-printer
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-skeleton-loader
        v-if="isLoading"
        class="mx-auto"
        type="table">
    </v-skeleton-loader>
    <!-- sortierung -->
    <v-data-table
        v-if="!isLoading"
        id="data-table"
        :headers="activeHeaders"
        :items="[data]"
        disable-pagination
        :hide-default-footer="true"
        :search="search"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        multi-sort
        disable-sort>
      <template v-slot:body="{ items, headers }">
        <tbody>
        <tr
            v-if="items[0].redmine"
            v-for="(item, idx, k) in items[0].redmine"
            class="redmine"
            :key="idx">
          <td
              :style="cellStyling(item, header)"
              v-for="(header, key) in headers"
              :key="key"
              class="text-center">
            <v-edit-dialog
                v-if="!header.is_custom_column">
              <a v-if="validLink(item, header)" target="_blank" :href="item[allHeaders.find(data => data.key === header.link).key]" class="flex">
                <div v-if="(header.type === 'date') && (item[header.key] !== '') && (item[header.key]) && (item[header.key] !== null) && (item[header.key] !== '0001-01-01')">
                  {{
                    new Date(item[header.key]).toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                </div>
                <v-checkbox
                    v-if="(header.type === 'boolean')"
                    @change="saveCustomData(item, header)"
                    v-model="item[header.key]"
                    false-value="false"
                    true-value="true">
                </v-checkbox>
                <div v-if="(header.type !== 'date') && (header.type !== 'boolean')">{{ item[header.key] }}</div>
                <div v-if="header.type === 'percent'">%</div>
              </a>
              <div v-else class="flex">
                <div v-if="(header.type === 'date') && (item[header.key] !== '') && (item[header.key]) && (item[header.key] !== null) && (item[header.key] !== '0001-01-01')">
                  {{
                    new Date(item[header.key]).toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                </div>
                <v-checkbox
                    v-if="(header.type === 'boolean')"
                    @change="saveCustomData(item, header)"
                    v-model="item[header.key]"
                    false-value="false"
                    true-value="true">
                </v-checkbox>
                <div v-if="(header.type !== 'date') && (header.type !== 'boolean')">{{ item[header.key] }}</div>
                <div v-if="(header.type === 'percent') && (item[header.key] === null)">0</div>
                <div v-if="header.type === 'percent'">%</div>
              </div>
            </v-edit-dialog>
            <v-edit-dialog
                v-else
                @close="saveCustomData(item, header)"
                ref="table"
                @open="saveCurrentDatePicker(header.type, item[header.key])">
              <a v-if="validLink(item, header)" target="_blank" :href="item[allHeaders.find(data => data.key === header.link).key]" class="flex">
                <div v-if="(header.type === 'date') && (item[header.key] !== '') && (item[header.key]) && (item[header.key] !== null) && (item[header.key] !== '0001-01-01')">
                 {{
                    new Date(item[header.key]).toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                </div>
                <v-checkbox
                    v-if="(header.type === 'boolean')"
                    @change="saveCustomData(item, header)"
                    v-model="item[header.key]"
                    false-value="false"
                    true-value="true">
                </v-checkbox>
                <div v-if="(header.type !== 'date') && (header.type !== 'boolean')">{{ item[header.key] }}</div>
                <div v-if="header.type === 'percent'">%</div>
              </a>
              <div v-else class="flex">
                <div v-if="(header.type === 'date') && (item[header.key] !== '') && (item[header.key]) && (item[header.key] !== null) && (item[header.key] !== '0001-01-01')">
                  {{
                    new Date(item[header.key]).toLocaleDateString("de-DE", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  }}
                </div>
                <v-checkbox
                    v-if="(header.type === 'boolean')"
                    @change="saveCustomData(item, header)"
                    v-model="item[header.key]"
                    false-value="false"
                    true-value="true">
                </v-checkbox>
                <div v-if="(header.type !== 'date') && (header.type !== 'boolean')">{{ item[header.key] }}</div>
                <div v-if="(header.type === 'percent') && (item[header.key] === null)">0</div>
                <div v-if="header.type === 'percent'">%</div>
              </div>
              <template v-slot:input>
                <v-text-field
                    v-if="(header.type === 'text')"
                    v-model="item[header.key]"
                    :label="item[header.key]"
                    :placeholder="item[header.key]"
                    single-line>
                </v-text-field>
                <v-text-field
                    v-if="header.type === 'number'"
                    v-model="item[header.key]"
                    :label="item[header.key]"
                    single-line
                    type="number">
                </v-text-field>
                <v-text-field
                    v-if="header.type === 'link'"
                    v-model="item[header.key]"
                    :label="item[header.key]"
                    single-line
                    type="link">
                </v-text-field>
                <v-date-picker
                    v-if="header.type === 'date'"
                    v-model="currentDate"
                    @close="closeDatePicker(currentDate, item[header.key], item, header)">
                    <v-card-actions class="ml-auto">
                      <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeDatePicker(currentDate, item[header.key], item, header)">Cancel</v-btn>
                        <v-btn color="primary" @click="closeDatePicker(item[header.key], currentDate, item, header)">OK</v-btn>
                    </v-card-actions>
                </v-date-picker>
              </template>
            </v-edit-dialog>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import print from "print-js";
import { mapGetters, mapState } from "vuex";
import { compareWithOperator } from "../helper";
import {getTableData, updateTableData} from "../api";
export default {
  name: "Table",

  data() {
    return {
      search: "",
      sortBy: 'fat',
      sortDesc: false,
      currentDate: new Date(),
    };
  },
  computed: {
    ...mapGetters({
      data: "data",
      activeHeaders: "activeHeaders",
      allHeaders: "headers",
      customActiveColumns: "activeCustomColumns",
      highlightRules: "highlightRules",
      highlightRuleColumnIds: "highlightRuleColumnIds",
    }),
    ...mapState({
      isLoading: "isLoading",
    }),
  },
  methods: {
    replaceAllSpaces(string) {
      let currentString = string;
      for (let i = 0; i < currentString.length; i++) {
        currentString = currentString.replace(' ','_');
      }
      return currentString;
    },
    saveCustomData(item, header) {
      this.$store.dispatch("updateCustomData", { item, header });
    },
    saveRedmineData(item, header) {
      this.$store.dispatch("updateRedmineData", { item, header });
    },

    printTable() {
      print("data-table", "html");
    },
    getColor(compareOperators, highlightRule, rule, compareValue, header) {
      let color = undefined;

      if (compareWithOperator(compareOperators, compareValue.toString(), rule.compareValue)) {
        if (highlightRule.highlight_row === true) {
          color = "background: " + highlightRule.color.background + "; color:" + highlightRule.color.font;
        }
        else {
          if (highlightRule.highlight_columns) {
            highlightRule.highlight_columns.forEach(column => {
              if (column.key === header.key) {
                color = "background: " + highlightRule.color.background + "; color:" + highlightRule.color.font;
              }
              else {
                color = undefined;
              }
            });
          }
        }
      }
      else {
        color = undefined;
      }
      return color;
    },
    activeColor(highlightRule, item, header) {
      let color = undefined;
      let compareOperators;
      let compareValue;

      for (let rule of highlightRule.rule_json) {
        compareValue = item[rule.columnValue.key.toLowerCase()];
        compareOperators = rule.compareOperators;
        try {
          if ((item[rule.columnValue.key.toLowerCase()]).includes('-')) {
            compareOperators = rule.dateCompareOperators;
          }
        }
        catch (e) {}
        if (item[rule.columnValue.key.toLowerCase()] === "0001-01-01") {
          color = undefined;
          continue;
        }
        if (rule.columnValue.key === undefined) {
          color = undefined;
          continue;
        }
        if (((item[rule.columnValue.key.toLowerCase()]) === undefined) || ((item[rule.columnValue.key.toLowerCase()]) === null)) {
          compareValue = "null";
        }
        color = this.getColor(compareOperators, highlightRule, rule, compareValue, header);
        if (color === undefined) {
          break;
        }
      }
      return color;
    },
    cellStyling(item, header) {
      let color = undefined;
      let cellColor = undefined;
      let currentPriority;
      for (let highlightRule of this.highlightRules) {
        if (highlightRule.is_active === false) {
          continue;
        }
        if (currentPriority === undefined) {
          currentPriority = highlightRule.priority;
        }
        if ((color === undefined) || (highlightRule.priority > currentPriority)) {
          color = this.activeColor(highlightRule, item, header);
        }
        if (color !== undefined) {
          cellColor = color;
        }
        if (highlightRule.priority > currentPriority)
          currentPriority = highlightRule.priority;
      }
      return cellColor;
    },
    reload() {
      this.$store.dispatch("updateTableData");
    },
    closeDatePicker(currentDate, data, item, header) {
      currentDate = data;
      item[header.key] = currentDate;
      this.$refs.table.forEach(element => {element.isActive = false;});
    },
    saveCurrentDatePicker(type, date) {
      if (type !== "date")
        return
      this.currentDate = date;
    },
    validLink(item, header) {
      if (!header.link)
        return false;
      if (!item[this.allHeaders.find(data => data.key === header.link).key])
        return false
      if (!item[this.allHeaders.find(data => data.key === header.link).key].includes('://'))
        if (item[header.key])
          if (item[header.key].includes('://'))
            header.link = header.key;
          else
            return false;
        else
          return false;
      return true;
    }
  },
};
</script>

<style lang="css">
  #data-table .v-data-table__wrapper {
    max-height: calc(100vh - 148px);
    overflow: auto;
  }
  thead th {
    background: #ddd;
    border: 1px solid #aaa;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  td {
    border: 1px solid #aaa
  }
  /* Zuweisbar Settings/Allgemeine Einstellungen/Theme Farbe Ändern */
  .v-application .v-input--selection-controls__input .primary--text{
    color: #828282 !important;
  }
  .v-application a {
    color: inherit !important;
  }
</style>
